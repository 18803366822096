module controllers {
    export module tariff {
        interface ITariffCodeCalculateDutiesScope extends ng.IScope {
        }

        export class tariffCodeCalculateDutiesCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'tariffPriorityService',
                'productTypeService',
                'classificationValueService',
                'tariffCountryService',
                'productTariffService',
                'lookupService',
                'countryService',
                'dutyCalculatorService',
                'bsLoadingOverlayService',
                '$anchorScroll',
                "tariffCountryGroupService",
                'IsLexisNexis'
            ];

            searchAccordian: boolean = true;

            apiTariffCodes: uiGrid.IGridApi;
            //UoM Grid
            apiUoM: uiGrid.IGridApi;

            apiAddCodes: uiGrid.IGridApi;

            dutyCalculation: interfaces.customs.IDutyCalculation;

            tariffCurrency:  interfaces.applicationcore.IDropdownModel;

            selectedTariffCountry: number;
            selectedTaxRegime: interfaces.tariff.CountryGroupDropDown;
            selectedCurrency:  interfaces.applicationcore.IDropdownModel;
            currencies: interfaces.applicationcore.IDropdownModel[] = [];
            isSingleCurrency: boolean = true;
            isSingleTaxRegime: boolean = false;  

            taxRegimeList: interfaces.tariff.CountryGroupDropDown[] = [];

            constructor(
                private $scope: ITariffCodeCalculateDutiesScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public tariffPriorityService: services.master.tariffPriorityService,
                public productTypeService: services.master.productTypeService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public tariffCountryService: interfaces.tariff.ITariffCountryService,
                public productTariffService: services.tariff.productTariffService,
                public lookupService: interfaces.applicationcore.ILookupService,
                public countryService: interfaces.applicationcore.ICountryService,
                public dutyCalculatorService: interfaces.customs.IDutyCalculatorService,
                private bsLoadingOverlayService,
                private $anchorScroll: ng.IAnchorScrollService,
                public tariffCountryGroupService: interfaces.tariff.ITariffCountryGroupService,
                private IsLexisNexis: boolean
            ) {
                this.Reset();

                /*
                 Moved this code to the AutoLogin page, which will specifiy which StyleSheet
                 * if (this.IsLexisNexis) {
                    angular.element('head').append('<link href="templates/styles/lexisnexis.css" rel="stylesheet" />');
                } */            

            }

            loadTaxRegime(){
                if (this.dutyCalculation.CountryOfOrigin){

                    let CountryGroupDropDownSearch: interfaces.tariff.CountryGroupDropDownSearch = {
                        declarationCountryId: this.dutyCalculation.DeclarationCountry.Id,
                        originCountryId: this.dutyCalculation.CountryOfOrigin.Id,
                        simulationDate: this.dutyCalculation.SimulationDate
                    }

                    return this.tariffCountryGroupService.getCountryGroupDropDownList(
                    ).save({},CountryGroupDropDownSearch, (data: interfaces.tariff.CountryGroupDropDown[]) => {
                        this.taxRegimeList = [];
                        this.taxRegimeList = data;                       
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;                

                }
            }

            COOChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                   this.dutyCalculation.CountryOfOrigin = model;
                   console.log('COO',this.dutyCalculation.CountryOfOrigin);
                    this.loadTaxRegime().then(()=>{

                     console.log("this.taxRegimeList",this.taxRegimeList);

                        if (this.taxRegimeList.length === 1) {
                            this.isSingleTaxRegime = true;
                            this.selectedTaxRegime = this.taxRegimeList[0];
                            this.dutyCalculation.TradeRegime = this.selectedTaxRegime;
                             
                          }else if (this.taxRegimeList.length == 2) {
                            this.isSingleTaxRegime = false;
                            this.selectedTaxRegime = this.taxRegimeList.find(
                                (i) => i.isGeneral === false
                              );
                              this.dutyCalculation.TradeRegime = this.selectedTaxRegime;
                          }
                          else{
                            this.isSingleTaxRegime = false;
                            this.selectedTaxRegime = null;
                            this.dutyCalculation.TradeRegime = null;
                          }  
                          
                          if (this.taxRegimeList.length === 0) {
                            this.isSingleTaxRegime = true;
                            this.selectedTaxRegime =  <interfaces.tariff.CountryGroupDropDown>{
                                Id: 0,
                                Code: "GEN",
                                Description: "General",
                                Display: "GEN - General", 
                                isGeneral: true
                            };
                            this.taxRegimeList.push(this.selectedTaxRegime);
                            this.dutyCalculation.TradeRegime = this.selectedTaxRegime;
                            console.log('this.dutyCalculation.TaxRegime',this.dutyCalculation.TradeRegime);   
                        }
                    });                 
                        
                }
            }
            
            loadCountries() {
                var defered = this.$q.defer();
                this.countryService.getDropdownList().query({}, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });
                return defered.promise;
            }

            loadCurrencies(){
                return this.dutyCalculatorService.getCurrencies(this.selectedTariffCountry).query({}, (data: interfaces.applicationcore.IDropdownModel[]) => {
                    this.currencies = data;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }


            loadTariffCountries() {
                var defered = this.$q.defer();
                this.tariffCountryService.getDropdownList().query({}, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });
                return defered.promise;
            }

            CODChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.selectedTariffCountry = model.Id;
                    this.loadCurrencies().then(()=>{

                        if (this.currencies.length === 1) {
                            this.isSingleCurrency = true;
                            this.selectedCurrency = this.currencies[0];
                            this.dutyCalculation.Currency = this.selectedCurrency;
                             
                          }else{
                            this.isSingleCurrency = false;
                          }

                        //We have more than one currency for this tariff country
                        //lets find the default currency for this country
                         
                        this.dutyCalculatorService.GetDeclarationCountryCurrency(model.Id).query({}, (data: interfaces.applicationcore.ICurrencyViewModel) => {
                            this.dutyCalculation.DeclarationCountryCurrency = data;
                            
                            this.selectedCurrency = this.currencies.find(
                                (i) => i.Code === data.Code
                              );

                              this.dutyCalculation.Currency = this.selectedCurrency;

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
    
                        });
                     
                        
                    })
                    /*this.dutyCalculatorService.GetDeclarationCountryCurrency(model.Id).query({}, (data: interfaces.applicationcore.ICurrencyViewModel) => {
                        this.dutyCalculation.DeclarationCountryCurrency.Code = data.Code;
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);

                    });*/
                        
                }
            }

            CurrencyChanged(model: interfaces.applicationcore.IDropdownModel){
                if (model){
                    this.selectedCurrency = model;
                    this.dutyCalculation.Currency = this.selectedCurrency;
                    
                }
            }

            registerTariffCodesApi(gridApi: uiGrid.IGridApi) {
                this.apiTariffCodes = gridApi;
            }

            gvwTariffCodes: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                onRegisterApi: (gridApi) => { this.registerTariffCodesApi(gridApi); },
                columnDefs: [
                    {
                        name: 'TAXTYPE',
                        displayName: 'Tax Type',
                        field: 'TaxType',
                        cellTemplate: `<div class="ui-grid-cell-contents">{{row.entity.TaxTypeCode}}</div>`,
                        enableSorting: false,
                        width: 100
                    }, {
                        name: 'DESCRIPTION',
                        displayName: 'Description',
                        field: 'Description',
                        cellTemplate: `<div class="ui-grid-cell-contents">{{row.entity.TaxTypeDescription}}</div>`,
                        enableSorting: false,
                        width: 465
                    },, {
                        name: 'TaxableValue',
                        displayName: 'CIF Value',
                        cellFilter: "number: 2",
                        cellClass: 'text-right',
                        field: 'TaxableValue',
                        width: 150,
                    },                
                    {
                        name: 'CURRENCY3',
                        displayName: '',
                        field: 'Currency',
                        cellTemplate: `<div class="ui-grid-cell-contents">{{ grid.appScope.tariffCodeCalculateDutiesCtrl.dutyCalculation.DeclarationCountryCurrency.Code }}</div>`,
                        enableSorting: false,
                        width: 60
                    }, {
                        name: 'DutyBase',
                        displayName: 'Duty/Tax Base',
                        cellFilter: "number: 2",
                        cellClass: 'text-right',
                        field: 'DutyBase',
                        width: 150,
                    },                
                    {
                        name: 'CURRENCY1',
                        displayName: '',
                        field: 'Currency',
                        cellTemplate: `<div class="ui-grid-cell-contents">{{ grid.appScope.tariffCodeCalculateDutiesCtrl.dutyCalculation.DeclarationCountryCurrency.Code }}</div>`,
                        enableSorting: false,
                        width: 60
                    },
                    {
                        name: "FORMULA",
                        displayName: "Formula",
                        field: "DutyFormula",                        
                        cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                        enableSorting: false,
                        width: 200,
                    }, {
                        name: 'TAX',
                        displayName: 'Tax',
                        cellFilter: "number: 2",
                        cellClass: 'text-right',                      
                        field: 'Duty',
                        width: 150
                    }, 
                    {
                        name: 'CURRENCY2',
                        displayName: 'Currency',
                        field: 'Currency',
                        cellTemplate: `<div class="ui-grid-cell-contents">{{ grid.appScope.tariffCodeCalculateDutiesCtrl.dutyCalculation.DeclarationCountryCurrency.Code }}</div>`,
                        enableSorting: false,
                        width: 100
                    },
                ]
            };

            registerUoMApi(gridApi: uiGrid.IGridApi) {
                this.apiUoM = gridApi;
            }

            gvwUoM: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerUoMApi(gridApi); },
                columnDefs: [
                    {
                        name: 'UOM',
                        displayName: 'Unit of Measure',
                        field: 'TaxType',
                        cellTemplate: `<div class="ui-grid-cell-contents">{{row.entity.UnitOfMeasureCode}} - {{row.entity.UnitOfMeasureDescription}}</div>`,
                        enableSorting: false,
                        width: 250
                    }, {
                        name: 'Qty',
                        field: 'Quantity',
                        displayName: 'Quantity',
                        enableCellEditOnFocus: true,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="orderedQty" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" min="0" />
                                    </p>
                                </form>`,
                        cellClass: 'text-right',
                        enableSorting: false
                    }
                ]
            };


            registerAddCodesApi(gridApi: uiGrid.IGridApi) {
                this.apiAddCodes = gridApi;
            }

            gvwAddCodes: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerAddCodesApi(gridApi); },
                columnDefs: [
                    {
                        name: 'Code',
                        displayName: 'National Measure',
                        field: 'TaxType',
                        cellTemplate: `<div class="ui-grid-cell-contents">{{row.entity.NationalMeasureCode}}</div>`,
                        enableSorting: false,
                        width: 150
                    },
                    {
                        name: 'DESC',
                        displayName: 'Description',
                        field: 'Description',                        
                        cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                        enableSorting: false,
                        width: 220
                    }
                ]
            };
           
            Calculate() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'tariff.calculate'
                },
                    () => {
                        return this.CalculateDuty().then((data) => {
                            if (data) {
                                this.$anchorScroll("topAnchor");
                                this.searchAccordian = false;
                            } else {
                                this.$anchorScroll("topAnchor");
                                this.searchAccordian = true;
                                this.dutyCalculation.DutyList = [];//Clear list as errors so nothing to show;
                            }
                        });
                    });
            }

            CalculateDuty(): ng.IPromise<boolean> 
            {
                var defer = this.$q.defer<boolean>();
                        this.dutyCalculatorService.CalculateDuty().save({}, this.dutyCalculation, (data: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(data);

                            if (data.ID) {
                                this.getDutyCalculator(Number(data.ID));
                            }
                            defer.resolve(true);

                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            defer.resolve(false);
                        });
                
                return defer.promise;
            }

            getDutyCalculator(dutyCalculatorId: number): ng.IPromise<boolean>  {
                var defer = this.$q.defer<boolean>();             

                this.dutyCalculatorService.GetDutyCalculation(dutyCalculatorId).query({}, (data: interfaces.customs.IDutyCalculation) => {

                    this.dutyCalculation = data;
                    console.log('dutyCalc',this.dutyCalculation);
                    this.gvwTariffCodes.data = data.DutyList;
                    this.gvwUoM.data = data.UnitOfMeasureList;
                    this.gvwAddCodes.data = data.AdditionalCodeList;

                    defer.resolve(true);
                  
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defer.resolve(false);
                }

                );

                return defer.promise;
            }

            Reset()
            {
                this.dutyCalculation = {
                    Id: 0,
                    DutyCalculationId: 0,
                    OwnerEntityId:0,
                    DeclarationCountry: null,
                    DeclarationCountryCurrency: { Code: "ZAR", Symbol: "ZAR" },
                    Currency: null,                    
                    Reference: "",
                    DateCosted: null,
                    SimulationDate: moment.utc(),
                    TariffCode: "",
                    TariffDescription: "",
                    CountryOfOrigin: null,
                    CustomsValue: 0,
                    AdditionalCodeList: null,
                    UnitOfMeasureList: null,
                    DutyList: null,
                };

                this.countryService.GetCurrentCountry().get({  }, (result: interfaces.applicationcore.ICountry) => {
                  
                    this.dutyCalculation.DeclarationCountry = <interfaces.applicationcore.IDropdownModel>{
                            Id: result.Id,
                            Code: result.Code,
                            Description: result.Description,
                            Display: result.Code + " - " + result.Description,
                            Selected: true
                        };
                       
                        this.CODChanged(this.dutyCalculation.DeclarationCountry);   

                });

                this.searchAccordian = true;
            }

            Print()
            {
                //Print
               
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'tariff.calculate'
                },
                    () => {

                        this.dutyCalculatorService.saveToDocument(Number(this.dutyCalculation.Id), Number(this.IsLexisNexis)).then(() => {

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });

                    });
            }

            LookupTariff()
            {
                this.lookupService.openTariffLookup(this.dutyCalculation.DeclarationCountry.Id, Enum.EnumTariffCodeType.NationMeasure, false, this.dutyCalculation.TariffCode,0,163).result.then(
                    (result: interfaces.tariff.INationalMeasureCodeLookup) => {
                        this.Reset();
                        this.dutyCalculation.TariffCode = result.NationalMeasureCode;
                        this.dutyCalculation.SimulationDate = result.SimulationDate;
                        //this.dutyCalculation.TariffDescription = result.Description;

                        var dutyCalculationHSCode = <interfaces.customs.DutyCalculationHSCode>{
                            Code: result.NationalMeasureCode, CountryId: result.CountryId, SimulationDate: result.SimulationDate, Description: ""
                        }

                        this.dutyCalculatorService.GetHSCodeDescription().save({}, dutyCalculationHSCode, (data: interfaces.customs.DutyCalculationHSCode) => {
                            this.dutyCalculation.TariffDescription = data.Description;
                        
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);

                        });

                        this.countryService.get().get({
                            cntId: result.CountryId
                        }, (result: interfaces.applicationcore.ICountry) => {
                            this.dutyCalculation.DeclarationCountry = <interfaces.applicationcore.IDropdownModel>{
                                Id: result.Id,
                                Code: result.Code,
                                Description: result.Description,
                                Display: result.Code + " - " + result.Description,
                                Selected: true
                            };
                            this.CODChanged(this.dutyCalculation.DeclarationCountry);
                        })

                }, (errorResult) => {

                });
            }

        };

        angular.module("app").controller("tariffCodeCalculateDutiesCtrl", controllers.tariff.tariffCodeCalculateDutiesCtrl);
    }
}